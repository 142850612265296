import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import ClayAlert from "@clayui/alert";
import ClayModal, { useModal } from "@clayui/modal";
import ClayButton from "@clayui/button";
import { claySpriteImagePath } from "../../../service/util/claySpriteImagePath";
import {
    getAccessDeniedConfig,
    getToastMessages,
    removeAccessDeniedModal,
    removeToastAlert,
    getReLoginModal,
} from "../../../service/redux-helper/store/slice/globalAlertHandler.slice";
import { getEnvVariables } from "../../../../store/global/global.slice";
import { CrossIcon } from "../../icons/cross";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import parser from "html-react-parser";

const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html);
};

function GlobalAlertHandler() {
    const dispatch = useDispatch();
    const toastMessages = useSelector(getToastMessages);
    const permDeniedConfig = useSelector(getAccessDeniedConfig);
    const envVariables = useSelector(getEnvVariables);
    const reLoginConfig = useSelector(getReLoginModal);
    const location = useLocation();

    const onToastClose = (id) => {
        dispatch(removeToastAlert(id));
    };

    const { observer, onClose } = useModal({
        onClose: () => dispatch(removeAccessDeniedModal(false)),
    });

    return (
        <Fragment>
            {toastMessages.length ? (
                <ClayAlert.ToastContainer>
                    {toastMessages.map((value) => (
                        <ClayAlert
                            displayType={value.type}
                            className={value.position + "-alert"}
                            autoClose={value.timeout ? value.timeout : ""}
                            key={value.id}
                            onClose={() => {
                                onToastClose(value.id);
                            }}
                            spritemap={claySpriteImagePath()}
                        >
                            <span>{parser(sanitizeHtml(value.message))}</span>
                        </ClayAlert>
                    ))}
                </ClayAlert.ToastContainer>
            ) : null}
            {reLoginConfig.show ? (
                <ClayModal disableAutoClose={true} observer={observer} size="md">
                    <ClayModal.Header withTitle={false} className={"h-auto pt-3"}>
                        <ClayModal.Title className="text-danger font-weight-bold">
                            {"Session Expired"}
                        </ClayModal.Title>
                    </ClayModal.Header>
                    <ClayModal.Body className="m-2 mb-4 border-top-0">
                        {!location.pathname.includes("/web") && location.pathname !== "/" ? (
                            <span>
                                Your session has expired. Please{" "}
                                <a
                                    href={
                                        `${envVariables.authhub.authhubEntryPoint}&RelayState=` +
                                        location.pathname
                                    }
                                >
                                    click here
                                </a>{" "}
                                to login.
                            </span>
                        ) : (
                            <span>
                                You might have an active session in another tab and it is expired,{" "}
                                <a
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    click here
                                </a>{" "}
                                or open in new tab before accessing webpage.
                            </span>
                        )}
                    </ClayModal.Body>
                </ClayModal>
            ) : null}
            {permDeniedConfig.show ? (
                <ClayModal observer={observer} size="sm">
                    <ClayModal.Header withTitle={false} className={"h-auto pt-3 border-bottom"}>
                        <ClayModal.ItemGroup>
                            <ClayModal.Item>
                                <ClayModal.TitleSection>
                                    <ClayModal.Title className="text-danger font-weight-bold">
                                        {"Access Denied"}
                                    </ClayModal.Title>
                                </ClayModal.TitleSection>
                            </ClayModal.Item>
                        </ClayModal.ItemGroup>
                        {permDeniedConfig.clossable && (
                            <ClayButton displayType="unstyled" onClick={onClose}>
                                <CrossIcon />
                            </ClayButton>
                        )}
                    </ClayModal.Header>
                    <ClayModal.Body className="m-2 mb-4">
                        <span>This feature has been disabled by your administrator.</span>
                    </ClayModal.Body>
                </ClayModal>
            ) : null}
        </Fragment>
    );
}

export { GlobalAlertHandler };
