import React from "react";
import RowDetail from "../common/RowDetail";
import { AUTHHUB_LABEL, OKTA_LABEL } from "../../../../constants";

const OktaDetails = (props: any) => {
    const oktaDetails = props.userDetails.data.oktaProfile;
    const userEmail = props.userDetails.data.oktaProfile.email;
    const firstName = props.userDetails.data.oktaProfile.firstName;
    const lastName = props.userDetails.data.oktaProfile.lastName;
    let userType = "";
    let authLabel = "";
    if (props.userDetails.data.oktaProfile.GTMScreened !== "") {
        userType = props.userDetails.data.oktaProfile.GTMScreened === "Y" ? "Enterprise" : "Basic";
        authLabel = AUTHHUB_LABEL;
    }
    else {
        userType = (userEmail.indexOf("@broadcom") !== -1) ? "Internal" : "";
        authLabel = userType === "Internal" ? OKTA_LABEL : "";
    }

    return (
        <React.Fragment>
            <div id="oktaDetails" className="pt-4">
                <h2 className="text-red">{authLabel} Details</h2>

                <h1 className="text-red">{userType}</h1>

                <RowDetail label={"Name"} value={`${firstName} ${lastName}`} />
                <RowDetail label={"Email"} value={userEmail} />
                <RowDetail label={"Company"} value={oktaDetails.companyName} />
                <RowDetail label={"Created On"} value={oktaDetails.regDate} />
                <RowDetail label={`${authLabel} Status`} value={oktaDetails.oktaStatus} />
                <RowDetail label={"Password Changed"} value={oktaDetails.passwordChanged} />
                <RowDetail label={"Last Login"} value={oktaDetails.lastLogin} />
                <RowDetail label={"Last Updated"} value={oktaDetails.updateDate} />
                <RowDetail label={"Address Line1"} value={oktaDetails.addressLine1} />
                <RowDetail label={"Address Line2"} value={oktaDetails.addressLine2} />
                <RowDetail label={"County"} value={oktaDetails.county} />
                <RowDetail label={"State"} value={oktaDetails.state} />
                <RowDetail label={"Country"} value={oktaDetails.country} />
                <RowDetail label={"Postal Code"} value={oktaDetails.postalCode} />
                <RowDetail label={"ERP Username"} value={oktaDetails.employeeNumber} />
                <RowDetail label={"Division"} value={oktaDetails.division} />
                <RowDetail label={"WA User Type"} value={oktaDetails.waUserType} />
                <RowDetail label={"User Type Flag"} value={oktaDetails.userTypeFlag} />
                <RowDetail label={"User Type"} value={oktaDetails.userType} />
                <RowDetail label={"Application Entitlement"} value={oktaDetails.applicationEntitlement} />
                <RowDetail label={"Ext User Unique id"} value={oktaDetails.extUserUniqueId} />
                <RowDetail label={"Federated"} value={oktaDetails.federated ? "True" : "False"} />
                <RowDetail label={"idp Code"} value={oktaDetails.idpCode} />
                <RowDetail label={"idp User Id"} value={oktaDetails.idpUserId} />
                <RowDetail label={"Saas User"} value={oktaDetails.saasUser} />
                <RowDetail label={"Embargoed User"} value={oktaDetails.Embargoed} />
                <RowDetail label={`${authLabel} Groups`} value={oktaDetails.oktaGroupsNames?.map((val: any) => <><span>{val}</span><br /></>)} />

                <br/>
                <hr />

            </div>
        </React.Fragment>
    );
};

export default OktaDetails;