import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    getEnvVariables,
    getHeartbeat,
    getHeartbeatCalling,
    loadHeartbeat,
} from "../../../store/global/global.slice";
import { useAppDispatch } from "../../../utils/constants/hook";
import { showAlert } from "../../service/redux-helper/store/slice/globalAlertHandler.slice";
import { useLocation } from "react-router-dom";

const Heartbeat = () => {
    const dispatch = useAppDispatch();
    const heartbeat = useSelector(getHeartbeat);
    const heartbeatCalling = useSelector(getHeartbeatCalling);
    const envVariables = useSelector(getEnvVariables);
    const location = useLocation();
    useEffect(() => {
        // Warning logout toast will be shown when logged in session is expiring.
        // It will be shown when remaining time is 3 minutes.
        // Heartbeat api will talk to nodejs every 1 minute
        if (heartbeat && heartbeat.timeInMinutes === 3) {
            dispatch(
                showAlert({
                    display: "toast-warning",
                    type: "warning",
                    message: `<span> Your session is expiring soon. Please save your work or <a
                    href="${envVariables.authhub.authhubEntryPoint}&RelayState=${location.pathname}"
                    >Click here</a> to extend session.</span>`,
                }),
            );
        }
    }, [heartbeat]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(loadHeartbeat());
        }, 60000); //1minute

        if (!heartbeatCalling) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [heartbeatCalling]);

    return <></>;
};

export { Heartbeat };
